<template>
    <div class="join-family">
        <div class="join-family__header">
            <div class="join-family__header-l">
                我的家庭
            </div>
            <div class="join-family__header-r">
                <div class="member-list">
                    <div
                        v-for="item in createInfo.headImgList"
                        :key="item"
                        class="member-list__item"
                     >
                        <img :src="item" alt="" srcset="">
                    </div>
                </div>  
            </div>
        </div>
        <div class="join-family__content">
            <div class="inviter-name">
                {{ createInfo.inviterName }}
            </div>
            <div class="inviter-info">
                邀请你加入“{{ createInfo.familyName }}”
            </div>
        </div>
        <!-- 立即加入按钮 -->
        <div class="join-btn">
            <button :class="{'invalid': invalid}" @click="joinFamilyFn">{{invalid ? '邀请链接失效' : '立即加入'}}</button>
        </div>
    </div>
</template>
<script>
import { joinFamily, getShareInfo } from '@/api/family'
export default {
    name: 'JoinFamily',
    data() {
        return {
            createInfo: {},
            invalid: false
        }
    },
    created() {
        this.$toast.loading({
            message: '加载中...',
            forbidClick: true,   // 禁止背景点击
            duration: 10000,  //展示时间，0为持续展示
            overlay: true,  //是否显示背景遮罩层
        })  
        this.getShareInfo()
    },
    methods: {
        getShareInfo() {
            getShareInfo({
                wxShareToken: this.$route.query.wxShareToken
            }).then(res => {
                if (res) {
                    this.createInfo = res
                } else {
                    this.$router.push('/')
                }
                this.$toast.clear()
            }).catch(err => {
                this.$toast.clear()
                this.$toast(err.msg)
                if (err.code == 607) {
                    this.invalid = true
                }
            })
        },
        joinFamilyFn() {
            if (this.invalid) {
                return
            }
            const curTime = new Date().getTime()
            if (this.$route.query.expiredTime && curTime > this.$route.query.expiredTime) {
                this.$toast('邀请链接已过期')
                return
            }
            joinFamily({
                wxShareToken: this.$route.query.wxShareToken
            }).then(res => {
                this.$toast.success('加入成功') 
                setTimeout(() => {
                    this.$router.push('/family/list')
                }, 1000)
            }).catch(err => {
                this.$toast.clear()
                this.$toast(err.msg)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.join-family {
    .join-family__header {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        height: 1.02rem;
        width: 100vw;
        background: linear-gradient( 180deg, #F6F6F6 20%, #EEEEEE 100%);
        border-radius: 0 0 .16rem .16rem;
        box-shadow: 0 .02rem .04rem 0 rgba(0,0,0,0.20);
        align-items: center;  
        padding: 0 .36rem;
        .join-family__header-l {
            font-size: .32rem;
            color: #333333;
            line-height: .36rem;
        }
        .member-list {
            display: flex;
            align-items: center;
            .member-list__item {
                width: .64rem;
                height: .64rem;
                border: .02rem solid #dcdcdc;
                border-radius: 50%;
                overflow: hidden;
                margin-left: -.14rem;
                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }   
            }
        }
    }
    .join-family__content {
        padding-top: .56rem;
        .inviter-name {
            font-size: .32rem;
            color: #333333;
            line-height: .38rem;
            font-weight: 600;
            text-align: center;
        }
        .inviter-info {
            padding-top: .26rem;
            font-size: .28rem;
            color: #666666;
            line-height: .32rem;    
            text-align: center;
        }
    }
    .join-btn {
        position: fixed;
        bottom: .88rem;
        left: .94rem;
        right: .94rem;
        button {
            width: 100%;
            background: #68D4DC;
            border: none;
            border-radius: .58rem;
            color: #fff;
            font-size: .36rem;
            line-height: .84rem;
            height: .84rem;
            &.invalid {
                background: #999;
                cursor: not-allowed;
            }
        }
    }
}
</style>
